<template>
    <LoginForm></LoginForm>
</template>

<script>
    import LoginForm from '@/components/LoginForm';

    export default {
        name: 'Login',
        components: {
            LoginForm
        }
    }
</script>

<style lang="scss" scoped>
</style>>