<template>
  <div>
        <b-form class="login" @submit.prevent="login">
            <b-form-group id="input-group-1">
                <b-form-input
                    v-model="form.username"
                    type="text"
                    placeholder="Username"
                    required></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-2">
                <b-form-input
                    v-model="form.password"
                    type="password"
                    placeholder="Password"
                    required></b-form-input>
            </b-form-group>
            <p v-show="show_error" style="font-size: .9rem; color: var(--dark-accent-color)">Wrong Username and, or Password!</p>
            <b-button :disabled="form.username.length === 0 || form.password.length === 0" variant="primary" type="submit">Login</b-button>
        </b-form>
    </div>
</template>

<script>
export default {
    name: 'LoginForm',
    data() {
      return {
        form: {
          username: '',
          password: '',
        },
        show_error: false
      }
    },
    methods: {
        login: function () {
            this.$store.dispatch('login', this.form).then(() => {
                this.$router.push('/catalog');
            }).catch(() => {
                this.show_error = true;
            });
        }
    }
}
</script>

<style>
    .login {
        position: absolute;
        margin-top: 10em;
        left: 50%;
        transform: translateX(-50%);
    }
</style>